.game-board__container {
    align-items: center;
    width: 18vw;
    max-height: 79vh;
    aspect-ratio: 9/18;
    background-color: rgba(0, 0, 0, 0.6);
}

.game-board__header {
    align-items: center;
    justify-content: space-between;
    display: flex;
    width: 100%;
    height: 5%;
}

.game-board__rolls {
    display: flex;
    width: 100%;
    height: 5%;
    margin-top: 2%;
}

.img__roll {
    height: calc(100% - 2px);
    margin: 1px;
    aspect-ratio: 1/1;
}

.img__mm {
    height: calc(100% - 2px);
    margin: 1px;
    aspect-ratio: 1/1;
    margin-left: auto;
}

.img__spellchoice {
    height: calc(100% - 2px);
    margin: 1px;
    aspect-ratio: 1/1;
}

.header__icon {
    height: 100%;
    aspect-ratio: 1/1;
}

.player-info__container {
    display: flex;
    align-items: center;
    font-weight: 500;
}

.country__icon {
    height: 2vh;
    max-height: 2vh;
    margin-right: 4px;
}

.player1 {
    color: var(--player1);
}

.player2 {
    color: var(--player2);

}

.player3 {
    color: var(--player3);
}

.player4 {
    color: var(--player4);
}

.player5 {
    color: var(--player5);
}

.player6 {
    color: var(--player6);
}

.player7 {
    color: var(--player7);
}

.player8 {
    color: var(--player8);
}

.game-board {
    width: calc(100% - 1px);
    aspect-ratio: 9/14;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-rows: repeat(28, calc(100% / 28));
    grid-template-columns: repeat(18, calc(100% / 18));
    background-size: calc(100%/9) calc(100%/14);
    border-right: 1px solid rgba(0, 0, 0, 0.6);
    border-bottom: 1px solid rgba(0, 0, 0, 0.6);
    background-image:
            linear-gradient(to right, rgba(0, 0, 0, 0.6) 1px, transparent 1px),
            linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 1px, transparent 1px);
    margin-top: 0.5%;
}

.clipboard {
    width: calc(100% - 1px);
    aspect-ratio: 9/14;
    margin: 0;
    padding: 0;
    border:none;
    margin-top: 0.5%;
    background-color: rgba(0, 0, 0, 0.4);
    overflow: hidden;
    display: block;
    border-bottom: 1px solid #484848;
}

.clipboard textarea {
    width: 100%;
    height: 100%;
    background-color: transparent;
    color:white;
    line-break:auto;
    border: none;
    padding: 0;
    resize: none;
    margin: 0;
    font-size: 1.5vh;
    overflow: hidden;
}

.unit__icon {
    width:  100%;
    height: 100%;
}

.unit__icon__container {
    width: calc(200% - 2px);
    height: calc(200% - 2px);
    margin: 1px 1px 1px 1px;
}

.player__color-marker {
    width: 100%;
    height: 1%;
}

.player1__background {
    background-color: var(--player1);
}

.player2__background {
    background-color: var(--player2);
}

.player3__background {
    background-color: var(--player3);
}

.player4__background {
    background-color: var(--player4);
}

.player5__background {
    background-color: var(--player5);
}

.player6__background {
    background-color: var(--player6);
}

.player7__background {
    background-color: var(--player7);
}

.player8__background {
    background-color: var(--player8);
}

.copy-button {
    cursor: pointer;
    font-size: 1vw;
    font-weight: 500;
    width: 50%;
    padding: 1px;
    height: calc(100% - 2px - 2px);
    margin-top: 1px;
    margin-bottom: 1px;
    color: #ffffff;
    text-align: center;
    transition: all 0.2s;
    text-align: center;
}

.copied {
    background-color: darkgreen;
}

.copy-button:hover {
    color: #000000;
    background-color: #FFFFFF;
}

.sends__container {
    width: 74%;
    height: 100%;
    display: flex;
    overflow: hidden;
    margin-left: 1%;
    align-content: flex-start;
    align-items: flex-start;
	flex-wrap: wrap-reverse; 
}

.stats__container {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0px;
    align-items: flex-start;
	flex-wrap: wrap-reverse; 
    justify-content: flex-end;
}

.send__icon__container {
    width: 11.5%;
    aspect-ratio: 1/1;
    height: 32.3%;
}

.sends__container span img {
    width: 100%;
    aspect-ratio: 1;
}

.sends__count {
    font-size: 1vw;
    color: white;
    margin-top: -90%;
    margin-right: 10%;
    text-align: end;
    display: block;
    text-shadow: 1px 1px 1px black;
    font-weight: 400;
}

.stats__icon {
    aspect-ratio: 1/1;
    height: 100%;
}

.stats__entry {
    height: 25%;
    margin-top: 2px;
    display: flex;
}

.stats__entry img {
    height: 100%;
}

.myth__region__container {
    width: 100%;
    height: 14%;
    display: flex;
    flex-wrap: wrap-reverse;
    align-content: flex-start;
    justify-content: flex-start;
    overflow: hidden;
    margin: 0px;
}

.stats__text {
    font-size: 1.8vh !important;
    font-weight: 500;
    margin: 0;
    text-align: right;
    display: inline-block;
    align-self: center;
}

.game-board__footer {
    width: 100%;
    display: flex;
    height: 5%;
    background-color: rgba(0, 0, 0, 0.3);
}

.leak__container {
    width: 50%;
    text-align: center;
    position: relative;
}

.leak__container div{
    font-weight: 400;
    font-size: 2vh;
    height: 100%;
    align-self: center;
}

.img__leaked__unit {
    aspect-ratio: 1/1;
    width: calc(100% / 9);
}

.leaked__creeps__container {
    position: absolute;
    bottom: 100%;
    width: 200%;
    text-align: left;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: flex-start;
}