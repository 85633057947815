.drawer__container {
    display: flex;
    justify-content: center;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    backdrop-filter: blur(8px);
    background: rgba(0,0,0,.7);
    z-index: 9999;
    animation: show-overlay .3s ease-in-out;
}

@keyframes show-overlay {
    0% {
        opacity: 0;
    }
    100% {
        opacity: .95;
    }
}

@keyframes show-overlay-content {
    0% {
        overflow: hidden;
        top: 16px;
        left: 16px;
        transform: scale(.1);
    }
}

.drawer__content {
    cursor: pointer;
    margin: 10vh 0;
    animation: show-overlay-content .5s ease-in-out;
    width: 75vw;
    overflow-y: scroll;
}

.drawer__content::-webkit-scrollbar {
    width: 0;
}

.drawer__button {
    position: absolute;
    right: 7vw;    
}

.drawer__menu-icon {
    fill: white;
    cursor: pointer;
    aspect-ratio: 1/1;
    width: 2vw;
}
