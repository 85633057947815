:root {
    --player1: #ff4d4d;
    --player2: #3d81ff;
    --player3: #fe8a0e;
    --player4: #a964e5;
    --player5: #fffc00;
    --player6: #1ce6b9;
    --player7: #20c000;
    --player8: #e55bb0;
}
