.match-history__container {
    width: 74.8vw;
    display: flex;
    flex-direction: column;
    background-color: rgb(26, 26, 26);
    margin-bottom: 10px;
    border: .1vw solid rgba(255, 255, 255, .0);
    backdrop-filter: blur(8px);
}

.match-history__container:hover {
    background-color: rgb(39, 39, 39)
}

.match-history__header {
    display: flex;
    justify-content: space-between;
    font-size: 2vh;
    padding: 8px;
    border: 1px solid rgba(255, 255, 255, .2);
    background-color: rgb(10,10,10, .5);
}

.match-history__content {
    display: flex;
    justify-content: space-between;
    padding: 1.5vh;
}

.match-history__result {
    width: 5vw;
    line-height: 100%;
    margin: auto 0;
}

.match-history__result img {
    height: 100%;
    width: 100%;
}

.side__container {
    width: 32vw;
    
    padding: 0 4px;
}

.players__container {
    display: flex;
    flex-direction: column;
    padding: 4px;
}
.elo__img {
    margin-top: .5vh;
    max-height: 2vh;
    margin-right: .2vw;
}


.player__entry {
    display: flex;
    align-items: center;
    padding: 0;
}

hr {
    margin: 4px 0 0 0;
    border-bottom: 1px solid rgba(255, 255, 255, .2);
    opacity: 0.5;
}

.won {
    background-color: rgba(0, 255, 0, .02);
    border: 1px solid rgba(0, 255, 0, .30);
}

.lost {
    background-color: rgba(255, 0, 0, .02);
    border: 1px solid rgba(255, 0, 0, .30);
}

.spell__container {
    display: flex;
    align-items: center;
    font-size: 2vh;
}

.legion__container img {
    max-height: 3vh;
    padding-left: 0.4vh
}

.legion__container {
    display: flex;
    align-items: center;
    font-size: 2vh;
}

.spell__container img {
    max-height: 3vh;

}

.mvp {
    font-size: 1.7vh;
    margin-left: 0.5vh;
}

.opener__container {
    display: flex;
    align-items: center;
    margin-left: auto;
    font-size: 2vh;
    margin-right: 1.4vh;
    padding-right: 1vh;
    border-right: 0.1vh solid rgba(255, 255, 255, .2);
    height: 3vh;
}

.opener__container img {
    max-height: 3vh;
}

.opener__count {
    position: relative;
    font-size: 1vw;
    bottom: 3vh;
    right: -2.3vh;
    color: white;
    text-shadow: 1px 1px 1px black;
    font-weight: 400;
}

.opener__img {
    margin-left: 0.5vh;
}

.opener__entry {
    width: 3vh;
    height: 3vh;
    margin: 0.1vh 0.1vh;
    aspect-ratio: 1/1;
}