.wave__header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 1vh;
    border-bottom: 1px solid black;
    background-color: rgba(0, 0, 0, 0.7);
}

.wave__description {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 3vh;
    flex-direction: column;
}

.wave__title__panel {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 3vh;
    margin-left: 6vw;
    margin-right: 6vw;
    width: 30vw;
}

.wave__creep__icon {
    height: 5vh;
    aspect-ratio: inherit;
    margin-left: 1vw;
}

.wave-change__button {
    margin: 0 8px;
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    color: inherit;
    cursor: pointer;
    transition: all 0.2s;
    border-radius: 0.12em;
    padding-left: 0.12em;
    padding-right: 0.12em;
    margin-top: auto;
    margin-bottom: auto;
}

.wave-change__button:hover {
    color: #000000;
    background-color: #FFFFFF;
}

.double {
    width: 36px;
    letter-spacing: -8px;
    margin: 0;
}

.disabled {
    color: grey;
    opacity: 0.5;
    cursor: default;
}

.display-none {
    display: none;
}

.wave__is-live {
    color: red;
    position: absolute;
    right: 12px;
}

.wave__is-final {
    color: green;
    position: absolute;
    right: 12px;
}
.wave__is-not-live {
    color: orange;
    position: absolute;
    right: 12px;
    cursor: pointer;
    transition: all 0.2s;
    border-radius: 0.12em;
    padding-left: 0.12em;
    padding-right: 0.12em;
}

.wave__is-not-live:hover {
    color: #000000;
    background-color: #FFFFFF;
}

.wave__king_hp_tooltip {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    font-size: 16px;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
   
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

.wave__kingHP:hover .wave__king_hp_tooltip {
    visibility: hidden;
    
}

.wave__kingHP {
    width: 15vw;
    transition: all 0.2s;
    height: 4vh;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    background-color: black;
}
.wave__health_bar {
    transition: all 0.2s;
    height: 4vh;
}

.wave__health_text {
    transition: all 0.2s;
    text-align: center;
    font-size: 2.3vh;
    top: 1vh;
    position: absolute;
    width: 15vw;
}

.wave__king__icon  {
    height: 4vh !important;
    aspect-ratio: 1/1;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

.wave__westKing__icon {
    margin-left: 0px !important;
    aspect-ratio: 1/1;
    border-right: 1px solid black;
}

.wave__eastKing__icon {
    margin-left: 0px !important;
    aspect-ratio: 1/1;
    border-left: 1px solid black;
}

.wave__westKing__health_bar, .wave__westKing {
    border-radius: 0.12em 0 0 0.12em;
    border-left: 1px solid black;
}

.wave__eastKing__health_bar, .wave__eastKing {
    border-radius: 0 0.12em 0.12em 0;
    border-right: 1px solid black;
}

.icon__recording {
    color: red;
}

.wave__selector {
    position: absolute;
    top: 5vh;
    height: 5vh;
    align-self: center;
    left: 50%;
    transform: translate(-50%, 0);
    flex-direction:row;
    display:flex;
}

.wave__select__container {
    width: 5vh;
    border: 1px solid black;
    cursor: pointer;
    height: 5vh;
    display: flex;
    margin: 0;
}

.wave__select__icon {
    width: 5vh;
    height: 5vh;
}

.wave__select__text {
    position: relative;
    left: -2.5vw;
    font-size: 1vw;
    text-shadow: 1px 1px 1px black;
}