.app {
  min-height: 100%;
  min-width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.game-boards__area {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  width:100vw;
  height:100vh;
}

.development__background {
  background-color: rgba(40, 44, 52, 50);
  display: block;
  max-width: 100vw;
  max-height: 100vw;
  aspect-ratio: 16/9;
  overflow: hidden;
}

.showEastToggle {
  position: fixed;
  left: 50vw;
  top: 0;
  left: 5vw;
  cursor: pointer;
  margin-top: 0.5vh;
  text-align: center;
  transition: all 0.2s;
  border-radius: 0.12em;
  padding-left: 0.12em;
  padding-right: 0.12em;
}

.button__toggle_visibility {
  position: absolute;
  right: 1vw;
  bottom: 2vh;
  border: 1px solid white
}

.button_bottomrow:hover {
  color: #000000;
  background-color: #FFFFFF;
}

.button_bottomrow {
  cursor: pointer;
  font-size: 0.8vw;
  font-weight: 500;
  width: 5vw;
  height: min(1.5vw, 3vh);
  padding: 0.35em;
  border: 0.1em solid #FFFFFF;
  margin: 0.5em 0 0.3em 0.3em;
  border-radius: 0.12em;
  color: #ffffff;
  text-align: center;
  transition: all 0.2s;
  background-color: rgba(0, 0, 0, 0.4);
  bottom: 5vh;
}

.button__toggle_tailing {
  position: absolute;
  right: 13vw;

  border: 1px solid white
}

.button_showEastToggle {
  position: absolute;
  right: 19vw;
  border: 1px solid white
}

.guideArrow {
  font-size: 20vh;
  text-shadow: 1px 1px 1px black;
  right: 1vw;
  bottom: 10vh;
  position: absolute;
  animation: MoveUpDown 1s linear infinite;
  position: absolute;
}

@keyframes MoveUpDown {
  0%, 100% {
    bottom: 7vh;
  }
  50% {
    bottom: 13vh;
  }
}

.config__container {
  margin-top: 5vh;
  width: 80vw;
  margin-left: 5vw;
}

.tooltip__container {
  display: flex;
  flex-direction: column;
  max-width: 250px;
  line-height: normal;
  font-size: 18px;
}

.tooltip__header__container {
  display: grid;
  grid-template-columns: 25px auto 25px;
  grid-template-rows: 25px;
}

.tooltip__container > span > img {
  width: 18px;
}

.tooltip__container > span {
  margin-top: 1%;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.tooltip__header__icon {
  width: 25px !important;
  height: 25px;
}

.tooltip__header__title {
}

.img__powerup {
  width: 2vw;
  aspect-ratio: 1/1;
  border-radius: 0.12em;
  color: #ffffff;
  text-align: center;
  transition: all 0.2s;
  background-color: rgba(0, 0, 0, 0.4);
  bottom: 5vh;
  padding: 1px;
}

.powerup__container {
  bottom: 5vh;
  position: absolute;
}

.floor_me {
  bottom: 5px !important;
}